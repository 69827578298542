
export function data() {
    return [
        {
            "name": "Dashboard",
            "path": "/dataStatistics",
            "icon": "appstore",
        },
        {
            "name": "用户",
            "icon": "user",
            "children": [
                {
                    "name": "用户列表",
                    "path": "/user"
                },
                {
                    "name": "系统充值记录",
                    "path": "/SystemRecharge"
                },
                // {
                //     "name": "用户提现", //旧功能
                //     "path": "/userwithdrawLog"
                // },


                {
                    "name": "用户提现", //以前的用户申请提现到银行卡
                    "path": "/userRefund"
                },

 
                // {
                //     "name": "用户反馈",
                //     "path": "/contactUs"
                // },
            ],
            "path": null
        },


 

        
        {
            "name": "商品",
            "path": "/GoodsList",
            "icon": "trophy",
        },


        {
            "name": "Deal",
            "icon": "tags",
            "path": "/promotionalgoodsList"
        },
        {
            "name": "优惠券",
            "path": "/couponList",
            "icon": "tag",
        },


         {
            "name": "订单",
            "path":  null,
            "icon": "snippets",

             "children": [
                 {
                    "name": "订单",
                    "path": "/orderlist"
                }, 

                {
                    "name": "订单退款",
                    "path": "/userOrderRefund"
                },
 

               
            ],


        },



        {
            "name": "合作伙伴",
            "path": "/empartner",
            "icon": "team",
        },





        {
            "name": "商家",
            "icon": "shop",
            "children": [
                {
                    "name": "商家列表",
                    "path": "/shop"
                },

                // {
                //     "name": "插件设置（已废弃）",
                //     "path": "/PlugInSet"
                // },


                // {
                //     "name": "商家服务费",
                //     "path": "/orderChargeFeeList"
                // },


                // {
                //     "name": "商家系统订单账单",
                //     "path": "/orderFeeList"
                // },

 

                {
                    "name": "结算清单",
                    "path": "/statementList"
                },



                

                // {
                //     "name": "年费流水",
                //     "path": "/AnnualFeeList"
                // },
                // ,{
                //     "name": "推荐商家",
                //     "path": "/recommend"
                // },{
                //     "name": "意向商家",
                //     "path": "/phonelog"
                // },


                
            ],
            "path": ""
        },
        {
            "name": "广告",
            "icon": "trademark",
            "children": [
                {
                    "name": "Banner 广告",
                    "path": "/Advertisement"
                },
            ],
            "path": ""
        },
        {
            "name": "套餐管理",
            "icon": "file-text",
            "children": [
                {
                    "name": "套餐",
                    "path": "/PackageList"
                },
                {
                    "name": "套餐申请记录",
                    "path": "/ApplyPackage"
                },
                // {
                //     "name": "套餐日志",
                //     "path": "/PackageLogList"
                // },
                // ,{
                //     "name": "推荐商家",
                //     "path": "/recommend"
                // },{
                //     "name": "意向商家",
                //     "path": "/phonelog"
                // },
            ],
            "path": ""
        },
        // {
        //     "name": "提现",
        //     "icon": "pay-circle",
        //     "children": [
        //         {
        //             "name": "商家提现",
        //             "path": "/withdrawLog"
        //         },
        //         // {
        //         //     "name": "用户提现",
        //         //     "path": "/userwithdrawLog"
        //         // }
        //     ],
        //     "path": null
        // },
        {
            "name": "设置",
            "path": null,
            "icon": "setting",
            "children": [
                {
                    "name": "管理员",
                    "path": "/admin"
                },
                {
                    "name": "上帝密码",
                    "path": "/set"
                },
                /*
                {
                    "name": "商家提现",
                    "path": "/withdrawLog"
                },
                */
                {
                    "name": "国家管理",
                    "path": "/regionList"
                },
                {
                    "name": "语言管理",
                    "path": "/language"
                },
                {
                    "name": "系统公告",
                    "path": "/AnnouncementList"
                },
                {
                    "name": "公共假期",
                    "path": "/holidayList"
                },

                {
                    "name": "皮肤管理",
                    "path": "/styleList"
                },
                {
                    "name": "隐私与协议",
                    "icon": "snippets",
                    "children": [
                        {
                            "name": "隐私政策",
                            "path": "/Privacy"
                        },
                        {
                            "name": "用户协议",
                            "path": "/Agreement"
                        },
                    ],
                    "path": null
                },
                {
                    "name": "打印机",
                    "icon": "snippets",
                    "children": [
                        {
                            "name": "打印机列表",
                            "path": "/printerCategoryList"
                        },
                        {
                            "name": "打印机统计",
                            "path": "/PrinterAllList"
                        },
                    ],
                    "path": null
                },
                {
                    "name": "第三方支付",
                    "icon": "snippets",
                    "children": [

                        {
                            "name": "支付设置",
                            "path": '/payConfig',
                        },
                        {
                            "name": "Stripe设置",
                            "path": '/StripeConfig',
                        },

                        {
                            "name": "Paypal设置",
                            "path": "/PaypalConfig"
                        },
                        // {
                        //     "name": "RoyalPay设置",
                        //     "path": "/RoyalConfig"
                        // },
                    ],
                    "path": null
                },
                {
                    "name": "工具",
                    "icon": "tool",
                    "children": [
                        {
                            "name": "导航设置",
                            "path": "/navList"
                        },
                    ],
                    "path": null
                },


                {
                    "name": "行为",
                    "icon": "control",
                    "path": "/behavior"
                },
                {
                    "name": "销售区域",
                    "icon": "wallet",
                    "children": [
                        {
                            "name": "站点所属国家",
                            "path": '/SiteCountry'
                        },

                        {
                            "name": "州",
                            "path": "/salesStateList"
                        },


                        {
                            "name": "销售大区",
                            "path": "/salesAreaList"
                        },
                        {
                            "name": "销售小区",
                            "path": "/salesAreaSubList"
                        },
                        {
                            "name": "补充Suburb",
                            "path": "/addressSub"
                        },

                         {
                            "name": "联合大区",
                            "path": "/SalesAreaUnionList"
                        },


                    ],
                    "path": null
                },


                // {
                //     "name": "用户提现",
                //     "path": "/userwithdrawLog"
                // }
            ],
        },


        /*
        {
            "name": "文档",
            "path": "/documentList",
            "icon": "file",
        },
        */
 

        {
            "name": "文档",
            "icon": "file-text",
            "children": [
                {
                    "name": "分类",
                    "path": "/CategoryList"
                },
                {
                    "name": "文档",
                    "path": "/documentList"
                },
                // {
                //     "name": "套餐日志",
                //     "path": "/PackageLogList"
                // },
                // ,{
                //     "name": "推荐商家",
                //     "path": "/recommend"
                // },{
                //     "name": "意向商家",
                //     "path": "/phonelog"
                // },
            ],
            "path": ""
        },






           // {
           //  "name": "我的订阅",
           //  "path":"/mySubscription",
           //  "icon":"account-book",
           //  },



        {
            "name": "商家插件",
            "icon":"api",
            "children":[
                {
                    "name": "新建插件",
                    "path": "/PlugInEdit"
                },{
                    "name": "插件列表",
                    "path": "/PlugInList"
                }
            ],
            "path":null
        },

        
    ]
}