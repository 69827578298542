import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Upload, Icon, message, Button, List, Select, Modal, Card, Switch } from 'antd';
import { getShopDetail, putShop, QueryRegion, queryAddress, getPartnerList,getSalesAreaList, getSalesAreaUnionList, getSalesAreaSubList,getStateList } from "../../action/shopAction"
import { getPackageList, getLanguageList } from "../../action/authAction"
import { BaseUrl } from '../../util/function';
import { API } from '../../util/api';
const { Search } = Input;
function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result, img));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    if (file.status === "removed") {
        return true
    }
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}


class EditShop extends React.Component {

    state = {
        confirmDirty: false,
        autoCompleteResult: [],
        userData: "",
        is_use: "",
        is_pack_charge: "",
        logo_file: "",
        imageUrl: "",
        loading: false,
        is_line_up_pay: 0,
        address: "",
        suburbsList: "",
        isShow: true,
        foodLicenseLoading: false,
        businessLicenseImageUrl: "",
        businessLicenseImage: "",
        businessLicenseLoading: false,
        foodLicenseFileList: "",
        weChatLoading: false,
        weChatFileList: "",
        packageData: "",
        package_id: "",
        regionList: [],
        is_in_store: false,
        LanguageData: "",
        SalesAreaData: "",
        SalesAreaUnionData:"",
        packageItem: "",
        is_status: false,
        is_open_goods_annex: true,
        SalesAreaSubList: "",
        emailCodeVisible: false,
        verify_code_key: "",
        verify_code: "",
        email: ""
    };


    async componentDidMount() {
        const id = this.props.location.pathname.substring(10)
        let LanguageData = await getLanguageList()
        let SalesAreaData = await getSalesAreaList()
        let SalesAreaUnionData = await getSalesAreaUnionList()
        let EmpartnerData = await getPartnerList()
        let StateData = await getStateList()


        LanguageData = LanguageData.data.data
        let res = await getShopDetail(id, LanguageData[1].id)
        let packageData = await getPackageList(LanguageData[1].id)
        let data = res.data
        let packageList = packageData.data.data
        let fileList = [];
        let imgdata = data?.partnerFoodLicense?.data
        for (let i in imgdata) {
            fileList.push({
                uid: imgdata[i].id,
                name: imgdata[i].id,
                status: 'done',
                url: imgdata[i].image,
                sort: imgdata[i].sort === 0 ? "0" : imgdata[i].sort
            });
        }
        let weChatList = []
        let wechatData = data?.partnerWechatImage?.data

        for (let i in wechatData) {
            weChatList.push({
                uid: wechatData[i].id,
                name: wechatData[i].id,
                status: 'done',
                url: wechatData[i].image,
                sort: wechatData[i].sort === 0 ? "0" : wechatData[i].sort
            });
        }
        this.setState({
            userData: data,
            is_use: data.is_use,
            sales_area_sub_id: data.sales_area_sub_id,
            is_pack_charge: data.is_pack_charge,
            imageUrl: data.logo,
            businessLicenseImageUrl: data.business_license,
            is_line_up_pay: data.is_line_up_pay,
            is_in_store: data.is_in_store === 1 ? true : false,
            packageData: packageList,
            LanguageData: LanguageData,
            SalesAreaData: SalesAreaData.data.data,
            SalesAreaUnionData: SalesAreaUnionData.data.data,
             StateData: StateData.data.data,
            EmpartnerData: EmpartnerData.data.data,
            foodLicenseFileList: fileList,
            weChatFileList: weChatList,
            is_status: data.status === 1 ? true : false,
            is_open_goods_annex: data.is_open_goods_annex === 1 ? true : false,
        }, () => {
            this.HandleGetSalesAreaSub(data.sales_area_id)
            this.CheckIsShowCode()
        })

        //获取地区
        let RegionList = await QueryRegion();
        this.setState({
            regionList: RegionList.data.data
        })

        if (data) {
            for (let i in packageList) {
                if (packageList[i].id === data.package_id) {
                    this.setState({
                        packageItem: packageList[i]
                    })
                }
            }
        }

    }

    HandleGetSalesAreaSub = (id) => {
        let url = "?sales_area_id=" + id
        getSalesAreaSubList(url).then(res => {
            this.setState({
                SalesAreaSubList: res.data.data,
            })
        })
    }


    HandleGetLanguageData = async (language_id) => {
        const id = this.props.location.pathname.substring(10)
        let packageData = await getPackageList(language_id)
        getShopDetail(id, language_id).then(res => {
            let data = res.data
            let fileList = [];
            let imgdata = data?.partnerFoodLicense?.data
            for (let i in imgdata) {
                fileList.push({
                    uid: imgdata[i].id,
                    name: imgdata[i].id,
                    status: 'done',
                    url: imgdata[i].image,
                    sort: imgdata[i].sort === 0 ? "0" : imgdata[i].sort
                });
            }
            let weChatList = [];
            let wechatData = data?.partnerWechatImage?.data

            for (let i in wechatData) {
                weChatList.push({
                    uid: wechatData[i].id,
                    name: wechatData[i].id,
                    status: 'done',
                    url: wechatData[i].image,
                    sort: wechatData[i].sort === 0 ? "0" : wechatData[i].sort
                });
            }

            this.setState({
                userData: data,
                is_use: data.is_use,
                is_pack_charge: data.is_pack_charge,
                imageUrl: data.logo,
                businessLicenseImageUrl: data.business_license,
                is_line_up_pay: data.is_line_up_pay,
                is_in_store: data.is_in_store === 1 ? true : false,
                is_status: data.status === 1 ? true : false,
                is_open_goods_annex: data.is_open_goods_annex === 1 ? true : false,
                foodLicenseFileList: fileList,
                weChatFileList: weChatList,
                packageData: packageData.data.data,
            }, () => {
                this.CheckIsShowCode()
            })
        })
    }



    CheckIsShowCode = () => {
        let { name, phone, partnerAddress, title, email, id } = this.state.userData

        let param = ""
        param += "partner_id" + id
        let codeUrl = `${BaseUrl()}/api/getWxCode?scene=${param}&page=pages/index/home&width=10&partner_id=${id}`
        if (name && phone && title && email && partnerAddress && partnerAddress.streets && partnerAddress.suburbs) {
            this.setState({ CodeImage: codeUrl })
        }
    }


    handleChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file) {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (imageUrl, img) =>
                this.setState({
                    imageUrl,
                    loading: false,
                    logo_file: img
                }),
            );
        }
    };


    handleChangeBusinessLicense = info => {
        if (info.file.status === 'uploading') {
            this.setState({ businessLicenseLoading: true });
            return;
        }
        if (info.file) {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj || info.file, (businessLicenseImageUrl, img) =>
                this.setState({
                    businessLicenseImageUrl,
                    businessLicenseLoading: false,
                    businessLicenseImage: img
                }),
            );
        }
    };

    handleUpload = info => {
        if (info.file.status === 'uploading') {
            this.setState({ foodLicenseLoading: true });
            return;
        }
        if (info.file) {
            let IsOk = beforeUpload(info.file);
            if (IsOk) {
                this.setState({
                    foodLicenseFileList: info.fileList
                })
            }
        }
    };

    handleUploadWeChat = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file) {
            let IsOk = beforeUpload(info.file);
            if (IsOk) {
                this.setState({
                    weChatFileList: info.fileList
                })
            }
        }
    };

    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        this.setState({
            foodLicenseLoading: true,
            imageUrl: file.url || file.preview
        })
    };

    handlePreviewWeChat = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        this.setState({
            weChatLoading: true,
            imageUrl: file.url || file.preview
        })
    };

    handleCancel = () => {
        this.setState({
            foodLicenseLoading: false,
            weChatLoading: false
        });
    };


    onChange = (checked) => {
        if (checked === 0) {
            this.setState({ is_use: 1 })
        } else {
            this.setState({ is_use: 0 })
        }
    }

    onChangePack = (checked) => {
        if (checked) {
            this.setState({ is_pack_charge: 1 })
        } else {
            this.setState({ is_pack_charge: 0 })
        }
    }

    HandleChoosePayType = (e) => {
        this.setState({
            is_line_up_pay: e.target.value,
        });

    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values.is_use = this.state.is_use.toString()
                values.is_pack_charge = this.state.is_pack_charge.toString()
                values.is_line_up_pay = this.state.is_line_up_pay.toString()
                values.is_in_store = this.state.is_in_store ? "1" : "0"
                values.status = this.state.is_status ? "1" : "0"
                values.is_open_goods_annex = this.state.is_open_goods_annex ? "1" : "0"
                values.request_language_id = values.request_language_id
                if (this.state.userData.email !== values.email) {
                    values.verify_code_key = this.state.verify_code_key
                    values.verify_code = this.state.verify_code
                } else {
                    delete values.email
                }
                values["partner_bank_account[name]"] = values.partner_bank_account_name ? values.partner_bank_account_name : ""
                values["partner_bank_account[bsb]"] = values.partner_bank_account_bsb ? values.partner_bank_account_bsb : ""
                values["partner_bank_account[number]"] = values.partner_bank_account_number ? values.partner_bank_account_number : ""
                values["package[rate]"] = values.package_rate
                values["package[activity_service_rate]"] = values.package_activity_service_rate
                values["rate"] = values.rate ? parseInt(values.rate) / 100 : ""
                values["activity_service_rate"] = values.activity_service_rate ? parseInt(values.activity_service_rate) / 100 : ""



                this.SubmitData(values)
            }


        });
    };

    async SubmitData(data) {
        const { businessLicenseImage, logo_file, foodLicenseFileList, weChatFileList } = this.state
        let formData = new FormData()
        let imgposition = [];
        let wechatPosition = [];
        for (let i in data) {
            // if (data[i]) {
            formData.append(i, data[i])
            // }

        }
        if (foodLicenseFileList && foodLicenseFileList.length > 0) {
            for (let i in foodLicenseFileList) {
                imgposition.push(i);
                if (foodLicenseFileList[i].originFileObj) {
                    formData.append('partner_food_license[' + i + ']', foodLicenseFileList[i].originFileObj);
                }

            }
        }
        if (weChatFileList && weChatFileList.length > 0) {
            for (let i in weChatFileList) {
                wechatPosition.push(i);
                if (weChatFileList[i].originFileObj) {
                    formData.append('partner_wechat_image[' + i + ']', weChatFileList[i].originFileObj);
                }

            }
        }

        if (imgposition.length > 0) {
            formData.append('food_license_img_data', imgposition.toString());
        } else {
            formData.append('food_license_img_data', "99999");
        }
        if (wechatPosition.length > 0) {
            formData.append('partner_wechat_image_data', wechatPosition.toString());
        } else {
            formData.append('partner_wechat_image_data', "99999");
        }
        logo_file && formData.append("logo_file", logo_file)
        businessLicenseImage && formData.append("business_license_file", businessLicenseImage)
        const id = this.props.location.pathname.substring(10)
        await putShop(id, formData)
    }



    handleChangeEditor = (editorState) => {
        this.setState({ editorState })
    }


    checkCustomerCode(rule, value, callback) { 
       if ( ! /[0-9a-zA-Z]{5}/.test(value)) {
             callback(new Error("请输入5位数字或者字母"));
        }else if(value.length > 5){
             callback(new Error("请输入5位数字或者字母"));   
        } else {
            callback();
        } 
    }




    render() {
        const { getFieldDecorator } = this.props.form;

        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">Upload</div>
            </div>
        );

        const uploadBusinessLicenseButton = (
            <div>
                <Icon type={this.state.businessLicenseLoading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">Upload</div>
            </div>
        );

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };
        const { imageUrl, userData, isShow, suburbsList, packageData, loading, regionList, LanguageData, packageItem, foodLicenseLoading, weChatLoading, businessLicenseImageUrl, businessLicenseLoading, foodLicenseFileList, weChatFileList, EmpartnerData, SalesAreaData, SalesAreaUnionData, StateData,is_status, is_open_goods_annex, SalesAreaSubList, sales_area_sub_id, emailCodeVisible } = this.state;
        if (userData) {
            return (
                <Form {...formItemLayout} onSubmit={this.handleSubmit} className="waperBox FormView">
                    {/* <div style={{ height: 30 }}></div> */}
                    <Card style={{ margin: "30px" }}>
                        <Form.Item
                            label="ID"
                        >
                            <Input value={userData.id} disabled />
                        </Form.Item>
                        <Form.Item
                            label="BDID"
                        >
                            <Input value={userData.bdid} disabled />
                        </Form.Item>
                        <Form.Item
                            label="用户名"
                            style={{ margin: "0px" }}
                        >
                            {getFieldDecorator('name', {
                                rules: [{ required: true, message: '请输入长度3-16位的用户名!', whitespace: true }],
                                initialValue: userData.name,
                                getValueFromEvent: (event) => {
                                    return event.target.value.toLowerCase()
                                }
                            })(<Input />)}
                        </Form.Item>
                    </Card>

                    <Card style={{ margin: "30px" }}>
                        <Form.Item
                            label={<span>选择录入的语言</span>}
                        >
                            {getFieldDecorator('request_language_id', {
                                rules: [{ required: true, message: '请选择语言!' }],
                                initialValue: LanguageData[1].id
                            })(
                                <Select
                                    onChange={(e) => {
                                        this.HandleGetLanguageData(e)
                                    }}
                                >
                                    {LanguageData && LanguageData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item
                            label={<span>店铺后台默认语言</span>}
                        >
                            {getFieldDecorator('manage_language_id', {
                                rules: [{ required: true, message: '请选择语言!' }],
                                initialValue: userData.manage_language_id
                            })(
                                <Select>
                                    {LanguageData && LanguageData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item
                            label={<span>店铺前台默认语言</span>}
                            style={{ margin: "0px" }}
                        >
                            {getFieldDecorator('front_language_id', {
                                rules: [{ required: true, message: '请选择语言!' }],
                                initialValue: userData.front_language_id

                            })(
                                <Select>
                                    {LanguageData && LanguageData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                                </Select>
                            )}
                        </Form.Item>
                    </Card>

                    <Card style={{ margin: "30px" }}>
                        <Form.Item
                            label="选择店铺套餐"
                            style={{ margin: "0px" }}
                        >
                            {getFieldDecorator('package_id', {
                                rules: [{ required: true, message: '请选择套餐!' }],
                                initialValue: userData.package_id
                            })(<Select onChange={(e) => {
                                for (let i in packageData) {
                                    if (packageData[i].id === e) {
                                        this.setState({
                                            packageItem: packageData[i]
                                        })
                                    }
                                }
                            }}>
                                {packageData && packageData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                            </Select>)}
                        </Form.Item>
                    </Card>
                    <Card style={{ margin: "30px" }}>

                        <Form.Item
                            label="套餐商品订单服务费"
                        >
                            {getFieldDecorator('package_rate', {
                                initialValue: userData?.package?.rate
                            })(<Input  min={0} style={{ width: '100%' }} suffix="%" />)}
                        </Form.Item>
                        <Form.Item
                            label="套餐活动订单服务费"
                        >
                            {getFieldDecorator('package_activity_service_rate', {
                                initialValue: userData?.package?.activity_service_rate
                            })(<Input   min={0} style={{ width: '100%' }} suffix="%" />)}
                        </Form.Item>
                        {/* <Form.Item
                            label="商家订单定额服务费"
                        >
                            {getFieldDecorator('activity_order_fixed_fee', {
                                initialValue: userData?.activity_order_fixed_fee
                            })(<Input prefix="$" min={0} style={{ width: '100%' }} />)}
                        </Form.Item>
                        <Form.Item
                            label="商家商品订单服务费"
                        >
                            {getFieldDecorator('rate', {
                                initialValue: userData.rate ? parseFloat(userData.rate) * 100 : ""
                            })(<Input min={0} style={{ width: '100%' }} suffix="%" />)}
                        </Form.Item>
                        <Form.Item
                            label="商家活动订单服务费"
                        >
                            {getFieldDecorator('activity_service_rate', {
                                initialValue: userData.activity_service_rate ? parseFloat(userData.activity_service_rate) * 100 : ""
                            })(<Input min={0} style={{ width: '100%' }} suffix="%" />)}
                        </Form.Item> */}
                        <Form.Item
                            // label="商家商品订单定额服务费"
                            label="套餐定额订单服务费"
                        >
                            <Input   value={packageItem && packageItem.order_fixed_fee} prefix="$" min={0} style={{ width: '100%' }} />
                        </Form.Item>

                    </Card>

                    <Card style={{ margin: "30px" }}>
                        <Form.Item
                            label="店铺名称"
                        >
                            {getFieldDecorator('title', {
                                rules: [{ required: true, message: '请输入店铺名称', whitespace: true }],
                                initialValue: userData.title
                            })(<Input />)}
                        </Form.Item>



                        <Form.Item
                            label="Customer Code"
                        >
                            {getFieldDecorator('customer_code', {
                                rules: [{ 
                                    required: true,  
                                    message: "请输入5位数字或者字母", 
                                    whitespace: true  
                                },

                                { validator: (rule, value, callback) => { this.checkCustomerCode(rule, value, callback) } }

                                ],
                                initialValue: userData.customer_code
                                // })(<Input disabled={!IsSuper} />)}
                            })(<Input />)}
                        </Form.Item>




                    
                        {/* <Form.Item
                        label={<span>店铺简介</span>}
                    >
                        {getFieldDecorator('abstract', {
                            rules: [{ required: false }],
                            initialValue: userData.abstract
                        })(<Input />)}
                    </Form.Item> */}
                        <Form.Item
                            label="电话"
                        >
                            {getFieldDecorator('phone', {
                                // rules: [{ required: true, message: '请输入电话!', whitespace: true }],
                                initialValue: userData.phone
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item
                            label="备用电话"
                        >
                            {getFieldDecorator('reserve_phone', {
                                initialValue: userData.reserve_phone
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item
                            label={<span>Street No.</span>}
                        >
                            {getFieldDecorator('streets', {
                                rules: [{ required: false, message: '请输入Street!' }],
                                initialValue: userData.partnerAddress && userData.partnerAddress.streets ? userData.partnerAddress.streets : ""
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item
                            label={<span>suburb</span>}
                            style={{ margin: "0px" }}
                        >
                            {getFieldDecorator('suburbs', {
                                rules: [{ required: true, message: '请输入sub!' }],
                                initialValue: userData.partnerAddress && userData.partnerAddress.suburbs,
                            })(<Input onChange={(e) => {
                                e.persist()
                                let key = e.target.value
                                if (key.length > 2) {
                                    queryAddress(key).then(res => {
                                        this.setState({
                                            suburbsList: res.data,
                                            isShow: true
                                        })
                                    })
                                }
                            }}
                            />)}
                            {
                                suburbsList && isShow && <List
                                    size="small"
                                    bordered
                                    dataSource={suburbsList}
                                    renderItem={item =>
                                        <List.Item onClick={() => {
                                            this.props.form.setFieldsValue({
                                                suburbs: item.name + ", " + item.state.abbreviation + ", " + item.postcode
                                            })
                                            this.setState({ isShow: false, suburbsList: "" })
                                        }}>{item.name}, {item.state.abbreviation}, {item.postcode}</List.Item>
                                    }
                                />
                            }

                        </Form.Item>

                    </Card>

                    <Card style={{ margin: "30px" }}>
                        <Form.Item label="邮箱">
                            <div>
                                {getFieldDecorator('email', {
                                    rules: [
                                        {
                                            type: 'email',
                                            message: 'The input is not valid E-mail!',
                                        },
                                        { required: true, message: '请输入邮箱!' }
                                    ],
                                    initialValue: userData.email
                                })(
                                    <Search
                                        placeholder="请输入邮箱"
                                        enterButton="发送"
                                        onSearch={value => {
                                            let data = { "provider": "email", "email": value }
                                            fetch(API.EmailBASEURL + API.verifyCode, {
                                                method: 'POST',
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                },
                                                body: JSON.stringify(data),
                                            })
                                                .then(response => response.json())
                                                .then(data => {
                                                    this.setState({
                                                        emailCodeVisible: true,
                                                        verify_code_key: data.verify_code_key
                                                    })
                                                })
                                                .catch((error) => {
                                                    console.error('Error:', error);
                                                });

                                        }}
                                    />
                                )}
                                {
                                    emailCodeVisible &&
                                    <Input
                                        placeholder='请输入验证码'
                                        style={{ width: "300px" }}
                                        onChange={(e) => {
                                            e.persist()
                                            this.setState({
                                                verify_code: e.target.value
                                            })
                                        }}
                                    />
                                }
                            </div>
                        </Form.Item>


                        <Form.Item
                            label={<span>二级域名前缀</span>}
                        >
                            {getFieldDecorator('sld_domain', {
                                rules: [{ required: true, message: '请输入二级域名前缀!' }],
                                initialValue: userData.sld_domain
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item
                            label={<span>独立域名</span>}
                        >
                            {getFieldDecorator('domain', {
                                initialValue: userData.domain
                            })(<Input />)}
                        </Form.Item>

 

                    </Card>



                    {/* <Card style={{ margin: "30px" }}>
                        <Form.Item label="店铺状态">
                            <Switch checked={is_status} onChange={() => {
                                this.setState({
                                    is_status: !is_status
                                })
                            }} />
                        </Form.Item> */}
                    {/* <Form.Item label="开启附加商品">
                            <Switch checked={is_open_goods_annex} onChange={() => {
                                this.setState({
                                    is_open_goods_annex: !is_open_goods_annex
                                })
                            }} />
                        </Form.Item> */}
                    {/* </Card> */}



                            


                            

                   <Card style={{ margin: "30px" }}>

                    <Form.Item
                            label={<span>所属国家</span>}
                        >
                            {getFieldDecorator('region_id', {
                                rules: [{ required: true, message: '请选择国家!' }],
                                initialValue: userData.region_id
                            })(
                                <Select disabled>
                                    {regionList && regionList.map(el => <Select.Option key={el.id} value={el.id}>{el.full_name}</Select.Option>)}
                                </Select>
                            )}
                        </Form.Item>

                        {/* <Form.Item
                        label={<span>开店时间</span>}
                    >
                        {getFieldDecorator('announcement', {
                            rules: [{ required: false }],
                            initialValue: userData.announcement
                        })(<Input />)}
                    </Form.Item> */}




                        {/*


                        <Form.Item
                            label={<span>州</span>}
                        >
                            <Input value="NSW" disabled />
                        </Form.Item>


                        */}
                        





                         <Form.Item
                            label={<span>州</span>}
                        >
                            {getFieldDecorator('state_id', {
                                rules: [{ required: true, message: '请选择州' }],
                                initialValue: userData.state_id
                            })(
                                <Select
                                    onChange={(e) => {
                                        this.setState({ 
                                            //sales_area_union_id: ""
                                        }, () => {
                                            //this.HandleGetSalesAreaSub(e)
                                        })

                                    }}
                                >
                                    {StateData && StateData.map(el => <Select.Option key={el.id} value={el.id}>{el.state}</Select.Option>)}
                                </Select>
                            )}
                        </Form.Item>









                        <Form.Item
                            label={<span>所在联合大区</span>}
                        >
                            {getFieldDecorator('sales_area_union_id', {
                                rules: [{ required: false, message: '请选择联合销售大区!' }],
                                initialValue: userData.sales_area_union_id == 0 ? "" : userData.sales_area_union_id
                            })(
                                <Select
                                    onChange={(e) => {
                                        this.setState({ 
                                            //sales_area_union_id: ""
                                        }, () => {
                                            //this.HandleGetSalesAreaSub(e)
                                        })

                                    }}
                                >
                                    {SalesAreaUnionData && SalesAreaUnionData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                                </Select>
                            )}
                        </Form.Item>





                        <Form.Item
                            label={<span>所在销售大区</span>}
                        >
                            {getFieldDecorator('sales_area_id', {
                                rules: [{ required: false, message: '请选择销售大区!' }],
                                initialValue: userData.sales_area_id
                            })(
                                <Select
                                    onChange={(e) => {
                                        this.setState({
                                            SalesAreaSubList: "",
                                            sales_area_sub_id: ""
                                        }, () => {
                                            this.HandleGetSalesAreaSub(e)
                                        })

                                    }}
                                >
                                    {SalesAreaData && SalesAreaData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                                </Select>
                            )}
                        </Form.Item>





                        {
                            SalesAreaSubList &&
                            <Form.Item
                                label="销售小区"
                            >
                                {getFieldDecorator('sales_area_sub_id', {
                                    initialValue: sales_area_sub_id
                                })(
                                    <Select placeholder="请选择销售小区">
                                        {SalesAreaSubList && SalesAreaSubList.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                                    </Select>
                                )}
                            </Form.Item>
                        }

                   </Card>


                    <Card style={{ margin: "30px" }}>


                        <Form.Item
                            label={<span>合作伙伴</span>}
                        >
                            {getFieldDecorator('empartner_id', {
                                rules: [{ required: false, message: '请选择合作伙伴' }],
                                initialValue: userData.empartner_id == 0 ?"" : userData.empartner_id
                            })(
                                <Select
                                    onChange={(e) => {
                                        this.setState({ 
                                            empartner_id: ""
                                        }, () => {
                                             
                                        })

                                    }}
                                >
                                    {EmpartnerData && EmpartnerData.map(el => <Select.Option key={el.id} value={el.id}>{el.title}</Select.Option>)}


                                </Select>
                            )}
                        </Form.Item>


                   </Card>










                    <Card style={{ margin: "30px" }}>
                        <Form.Item
                            label={<span>公司名称</span>}
                        >
                            {getFieldDecorator('company', {
                                // rules: [{ required: true, message: '请输入公司名称!' }],
                                initialValue: userData.company
                            })(<Input />)}
                        </Form.Item>

                        <Form.Item
                            label={<span>ABN</span>}
                        >
                            {getFieldDecorator('abn', {
                                // rules: [{ required: true, message: '请输入ABN!' }],
                                initialValue: userData.abn
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item
                            label={<span>银行名称</span>}
                        >
                            {getFieldDecorator('partner_bank_account_name', {
                                initialValue: userData.partnerBankAccount ? userData.partnerBankAccount.name : ""
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item
                            label={<span>BSB</span>}
                        >
                            {getFieldDecorator('partner_bank_account_bsb', {
                                initialValue: userData.partnerBankAccount ? userData.partnerBankAccount.bsb : ""
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item
                            label={<span>银行账号</span>}
                        >
                            {getFieldDecorator('partner_bank_account_number', {
                                initialValue: userData.partnerBankAccount ? userData.partnerBankAccount.number : ""
                            })(<Input />)}
                        </Form.Item>

                        <Form.Item label={<span>营业执照</span>}>
                            <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                beforeUpload={beforeUpload}
                                onChange={this.handleChangeBusinessLicense}
                            >
                                {businessLicenseImageUrl && !businessLicenseLoading ? <img src={businessLicenseImageUrl} alt="avatar" style={{ width: '100%', objectFit: 'cover' }} /> : uploadBusinessLicenseButton}
                            </Upload>
                        </Form.Item>

                        <Form.Item
                            label="许可证"
                        >
                            <div>
                                <Upload
                                    listType="picture-card"
                                    fileList={foodLicenseFileList}
                                    onPreview={this.handlePreview}
                                    onChange={this.handleUpload}
                                    beforeUpload={() => {
                                        return false;
                                    }}
                                >
                                    {foodLicenseFileList.length >= 10 ? null : (
                                        <div>
                                            {/* <PlusOutlined /> */}
                                            <div className="ant-upload-text">Add Picture</div>
                                        </div>
                                    )}
                                </Upload>
                                <Modal
                                    visible={foodLicenseLoading}
                                    // title={previewTitle}
                                    footer={null}
                                    onCancel={this.handleCancel}
                                >
                                    <img alt="example" style={{ width: '100%' }} src={imageUrl} />
                                </Modal>
                            </div>
                        </Form.Item>
                        <Form.Item
                            label="微信二维码"
                        >
                            <div>
                                <Upload
                                    listType="picture-card"
                                    fileList={weChatFileList}
                                    onPreview={this.handlePreviewWeChat}
                                    onChange={this.handleUploadWeChat}
                                    beforeUpload={() => {
                                        return false;
                                    }}
                                >
                                    {weChatFileList.length >= 10 ? null : (
                                        <div>
                                            <div className="ant-upload-text">Add Picture</div>
                                        </div>
                                    )}
                                </Upload>
                                <Modal
                                    visible={weChatLoading}
                                    footer={null}
                                    onCancel={this.handleCancel}
                                >
                                    <img alt="example" style={{ width: '100%' }} src={imageUrl} />
                                </Modal>
                            </div>
                        </Form.Item>

                        {/* <Form.Item
                        label={<span>店铺logo</span>}
                    >
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            beforeUpload={beforeUpload}
                            onChange={this.handleChange}
                        >
                            {imageUrl && !loading ? <img src={imageUrl} alt="avatar" style={{ width: '100%', objectFit: 'cover' }} /> : uploadBusinessLicenseButton}
                        </Upload>
                    </Form.Item> */}
                    </Card>






                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            );

        }
        return <div />

    }
}

export default withRouter(Form.create()(EditShop))
