import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Card, Radio, Switch , Select,Tooltip, Icon, } from 'antd';
import { GetConfigSet, UpdateConfigSet } from "../../action/authAction";

class Behavior extends React.Component {

    state = {
        data: "",
        is_charge_packing_fee: false,
        is_open_prize: false,
        is_open_spu_many_help: false,
        is_moon_enable: false,
        is_in_store: true,
        is_on_delivery_express: true,
        is_on_delivery: true,//配送
        is_delivery_foot: true,//配送
        eat_in: true,
        take: true,
        stripe: true,
        paypal: true,
        royal_pay: true,
        TimeData:[
                {'id':'10',
                    'name':'10 mins'
                },

                {'id':'15',
                    'name':'15 mins'
                },

                {'id':'30',
                    'name':'30 mins'
                },

                {'id':'60',
                    'name':'60 mins'
                },



                ]

    };

    async componentDidMount() {
        let res = await GetConfigSet()
        let allData = res.data.data
        let data = []
        data.min_pay_fee_on_online = allData.find(el => el.key === "min_pay_fee_on_online").value
        // data.min_fee_by_offline_pay = allData.find(el => el.key === "min_fee_by_offline_pay").value
        data.user_single_refund_money = allData.find(el => el.key === "user_single_refund_money").value
        // data.payment_type = allData.find(el => el.key === "payment_type").value
        data.payment_method = allData.find(el => el.key === "payment_method").value
        data.is_charge_packing_fee = allData.find(el => el.key === "is_charge_packing_fee").value
        data.is_open_prize = allData.find(el => el.key === "is_open_prize").value
        data.is_open_spu_many_help = allData.find(el => el.key === "is_open_spu_many_help").value
        data.is_moon_enable = allData.find(el => el.key === "is_moon_enable").value
        data.is_in_store = allData.find(el => el.key === "is_in_store").value
        data.is_on_delivery_express = allData.find(el => el.key === "is_on_delivery_express").value
        data.is_delivery_foot = allData.find(el => el.key === "is_delivery_foot").value
        data.is_on_delivery = allData.find(el => el.key === "is_on_delivery").value
        data.in_store_type = allData.find(el => el.key === "in_store_type").value
        data.user_unpay_wait_time = allData.find(el => el.key === "user_unpay_wait_time").value
        data.user_refund_money_week_count = allData.find(el => el.key === "user_refund_money_week_count").value
        data.user_refund_money_month_count = allData.find(el => el.key === "user_refund_money_month_count").value
        data.system_reduce_points_value = allData.find(el => el.key === "system_reduce_points_value").value
        data.system_add_points_value = allData.find(el => el.key === "system_add_points_value").value
        data.system_points_exchange_set = allData.find(el => el.key === "system_points_exchange_set").value
        data.min_use_user_money = allData.find(el => el.key === "min_use_user_money").value
        this.setState({
            data: data,
            is_charge_packing_fee: data.is_charge_packing_fee == 1 ? true : false,
            is_open_prize: data.is_open_prize == 1 ? true : false,
            is_open_spu_many_help: data.is_open_spu_many_help === "1" ? true : false,
            is_moon_enable: data.is_moon_enable === "1" ? true : false,
            is_in_store: data.is_in_store == 1 ? true : false,
            is_on_delivery_express: data.is_on_delivery_express == 1 ? true : false,
            is_delivery_foot: data.is_delivery_foot == 1 ? true : false,
            is_on_delivery: data.is_on_delivery == 1 ? true : false,
            eat_in: data.in_store_type.eat_in == 1 ? true : false,
            take: data.in_store_type.take == 1 ? true : false,
            // stripe: data.payment_type.stripe === 1 ? true : false,
            // paypal: data.payment_type.paypal === 1 ? true : false,
            // royal_pay: data.payment_type.royal_pay === 1 ? true : false,
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                let { eat_in, take, is_charge_packing_fee, is_in_store,is_on_delivery_express, is_delivery_foot,is_on_delivery,stripe, paypal, royal_pay, is_open_prize, is_open_spu_many_help,is_moon_enable } = this.state
                let data = []
                for (let key in values) {
                    data.push({
                        "key": key,
                        "value": values[key],
                    })
                }
                data.push({ "key": "in_store_type", "value": { "eat_in": eat_in ? 1 : "0", "take": take ? 1 : "0" } })
                data.push({ "key": "is_charge_packing_fee", "value": is_charge_packing_fee ? "1" : "0" })
                data.push({ "key": "is_open_prize", "value": is_open_prize ? "1" : "0" })
                data.push({ "key": "is_open_spu_many_help", "value": is_open_spu_many_help ? "1" : "0" })
                data.push({ "key": "is_moon_enable", "value": is_moon_enable ? "1" : "0" })
                data.push({ "key": "is_in_store", "value": is_in_store ? "1" : "0" })
                data.push({ "key": "is_on_delivery_express", "value": is_on_delivery_express ? "1" : "0" })
                data.push({ "key": "is_delivery_foot", "value": is_delivery_foot ? "1" : "0" })
                data.push({ "key": "is_on_delivery", "value": is_on_delivery ? "1" : "0" })
                // data.push({ "key": "payment_type", "value": { "stripe": stripe ? 1 : "0", "paypal": paypal ? 1 : "0", "royal_pay": royal_pay ? 1 : "0" } })
                UpdateConfigSet(data)
            }
        });
    };


    render() {
        const { TimeData,data, is_charge_packing_fee,  is_on_delivery, eat_in, take, is_on_delivery_express,is_in_store,is_delivery_foot, stripe, paypal, royal_pay, is_open_prize, is_open_spu_many_help,is_moon_enable } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };


        return (

            <Form {...formItemLayout} onSubmit={this.handleSubmit} style={{ maxWidth: "1000px", marginLeft: "100px" }}>
                <Card style={{ marginBottom: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>订单金额设置</div>
                    <Form.Item
                        label="线上付款订单，最低金额"
                    >
                        {getFieldDecorator('min_pay_fee_on_online', {
                            rules: [{ required: false }],
                            initialValue: data.min_pay_fee_on_online
                        })(<Input prefix="$" disabled />)}
                    </Form.Item>
                    <Form.Item
                        label="单笔订单退款额度"
                    >
                        {getFieldDecorator('user_single_refund_money', {
                            rules: [{ required: false }],
                            initialValue: data.user_single_refund_money
                        })(<Input prefix="$" disabled />)}
                    </Form.Item>
                    <Form.Item
                        label="单笔使用钱包金额最小额度"
                    >
                        {getFieldDecorator('min_use_user_money', {
                            rules: [{ required: false }],
                            initialValue: data.min_use_user_money
                        })(<Input prefix="$" disabled />)}
                    </Form.Item>
                    {/* <Form.Item
                        label="现金付款订单，最低金额"
                    >
                        {getFieldDecorator('min_fee_by_offline_pay', {
                            rules: [{ required: false }],
                            initialValue: data.min_fee_by_offline_pay
                        })(<Input />)}
                    </Form.Item> */}
                </Card>
                {/* <Card style={{ marginBottom: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>第三方平台支付</div>
                    <Form.Item
                        label="选择支付渠道"
                    >
                        {getFieldDecorator('payment_type', {
                            rules: [{ required: false }],
                            initialValue: data.payment_type
                        })(
                            <Radio.Group>
                                <Radio value={"Royal Pay"}>Royal Pay</Radio>
                                <Radio value={"Stripe、Royal Pay"}>Stripe、Royal Pay</Radio>
                            </Radio.Group>
                        )}
                    </Form.Item>
                    <Form.Item
                        label="stripe"
                    >
                        <Switch checked={stripe} onChange={() => {
                            if (!stripe) {
                                this.setState({
                                    stripe: !stripe,
                                    royal_pay: false,
                                    paypal: false
                                })
                            } else {
                                this.setState({
                                    stripe: !stripe,
                                    paypal: true,
                                })
                            }

                        }} />
                    </Form.Item>
                    <Form.Item
                        label="royal pay"
                    >
                        <Switch checked={royal_pay} onChange={() => {
                            if (!royal_pay) {
                                this.setState({
                                    royal_pay: !royal_pay,
                                    stripe: false,
                                    paypal: false
                                })
                            } else {
                                this.setState({
                                    royal_pay: !royal_pay,
                                    paypal: true,
                                })
                            }
                        }} />
                    </Form.Item>
                    <Form.Item
                        label="paypal"
                    >
                        <Switch checked={paypal} onChange={() => {
                            if (!paypal) {
                                this.setState({
                                    paypal: !paypal,
                                    stripe: false,
                                    royal_pay: false
                                })
                            } else {
                                this.setState({
                                    paypal: !paypal,
                                    royal_pay: true
                                })
                            }
                        }} />
                    </Form.Item>
                </Card> */}
                <Card style={{ marginBottom: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>付款方式</div>
                    <Form.Item
                        label="选择付款方式"
                    >
                        {getFieldDecorator('payment_method', {
                            rules: [{ required: false }],
                            initialValue: data.payment_method
                        })(<Radio.Group>
                            <Radio disabled value={"0"}>不限</Radio>
                            <Radio value={"1"}>线上付款</Radio>
                            <Radio disabled value={"2"}>现金付款</Radio>
                        </Radio.Group>)}
                    </Form.Item>
                </Card>
                <Card style={{ marginBottom: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>打包费</div>
                    <Form.Item
                        label="打包费"
                    >
                        <Switch disabled checked={is_charge_packing_fee} onChange={() => {
                            this.setState({
                                is_charge_packing_fee: !is_charge_packing_fee
                            })
                        }} />
                    </Form.Item>
                </Card>
                {/* <Card style={{ marginBottom: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>开启派奖</div>
                    <Form.Item
                        label="开启派奖"
                    >
                        <Switch checked={is_open_prize} onChange={() => {
                            this.setState({
                                is_open_prize: !is_open_prize
                            })
                        }} />
                    </Form.Item>
                </Card> */}



                <Card style={{ marginBottom: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>小月亮</div>
                    <Form.Item
                        label="小月亮"
                    >
                        <Switch checked={is_moon_enable} onChange={() => {
                            this.setState({
                                is_moon_enable: !is_moon_enable
                            })
                        }} />
                    </Form.Item>
                </Card>


                


                <Card style={{ marginBottom: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>开启砍一刀</div>
                    <Form.Item
                        label="开启砍一刀"
                    >
                        <Switch checked={is_open_spu_many_help} onChange={() => {
                            this.setState({
                                is_open_spu_many_help: !is_open_spu_many_help
                            })
                        }} />
                    </Form.Item>
                </Card>




                <Card style={{ marginBottom: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>消费方式</div>


                    <div style={{display:"flex",    justifyContent: "space-evenly"  }} >
                    <div style={{width: "100%"  }}>
                    <Form.Item
                        label="到店"
                    >
                        <Switch checked={is_in_store} disabled onChange={() => {
                            this.setState({
                                is_in_store: !is_in_store,
                                take: is_in_store ? false : true,
                                eat_in: is_in_store ? false : true
                            })
                        }} />
                    </Form.Item>
                    <Form.Item
                        label="店内消费"
                        style={{ marginLeft: 40 }}
                    >
                        <Switch
                            checked={eat_in}
                            // disabled={!is_in_store}
                            disabled
                            onChange={() => {
                                if (!take && eat_in) {
                                    this.setState({
                                        take: true,
                                        eat_in: !eat_in
                                    })
                                } else {
                                    this.setState({
                                        eat_in: !eat_in
                                    })
                                }

                            }} />
                    </Form.Item>
                    <Form.Item
                        label="到店自提"
                        style={{ marginLeft: 40 }}
                    >
                        <Switch
                            checked={take}
                            // disabled={!is_in_store}
                            disabled
                            onChange={() => {
                                if (!eat_in && take) {
                                    this.setState({
                                        eat_in: true,
                                        take: !take
                                    })
                                } else {
                                    this.setState({
                                        take: !take
                                    })
                                }

                            }} />
                    </Form.Item>

                    </div>



                     <div style={{width: "100%"  }}>
                    <Form.Item
                        label="配送"
                    >
                        <Switch checked={is_in_store} disabled onChange={() => {
                            this.setState({
                                is_in_store: !is_in_store,
                                take: is_in_store ? false : true,
                                eat_in: is_in_store ? false : true
                            })
                        }} />
                    </Form.Item>
                    <Form.Item
                        label="送货"
                        style={{ marginLeft: 40 }}
                    >
                        <Switch
                            checked={is_on_delivery_express}
                            // disabled={is_on_delivery_express}
                            disabled
                            onChange={() => {
                               

                            }} />
                    </Form.Item>
                    <Form.Item
                        label="送餐"
                        style={{ marginLeft: 40 }}
                    >
                        <Switch
                            checked={is_delivery_foot} 
                            disabled
                            onChange={() => {

                                /*if (!eat_in && take) {
                                    this.setState({
                                        is_delivery_foot: true,
                                        
                                    })
                                } else {
                                    this.setState({
                                        is_delivery_foot: !take
                                    })
                                }*/

                            }} />
                    </Form.Item>

                    </div>



                    </div>


                </Card>



                <Card style={{ marginBottom: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>未付款订单取消时间
                            <Tooltip title={'在平台进行下单时的未付款时间'}>
                                <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                            </Tooltip>

                    </div>

                    {/*<Form.Item
                        label="未付款订单取消时间"
                    >
                        {getFieldDecorator('user_unpay_wait_time', {
                            rules: [{ required: false }],
                            initialValue: data.user_unpay_wait_time
                        })(<Input />)}
                    </Form.Item>
                    */}

                     <Form.Item
                        label={<span>未付款订单取消时间</span>}
                    >
                        {getFieldDecorator('user_unpay_wait_time', {
                            rules: [{ required: false}],
                            initialValue: data.user_unpay_wait_time
                        })(
                            <Select
                                onChange={(e) => {
                                    //this.handleGetPackageData(e)
                                }}
                            >
                                {TimeData && TimeData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                            </Select>
                        )}
                    </Form.Item>




                     
                </Card>






                <Card style={{ marginBottom: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>退款次数设置</div>
                    <Form.Item
                        label="单用户每周退款限制次数"
                    >
                        {getFieldDecorator('user_refund_money_week_count', {
                            rules: [{ required: false }],
                            initialValue: data.user_refund_money_week_count
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item
                        label="单用户每月退款限制次数"
                    >
                        {getFieldDecorator('user_refund_money_month_count', {
                            rules: [{ required: false }],
                            initialValue: data.user_refund_money_month_count
                        })(<Input />)}
                    </Form.Item>
                </Card>



                <Card style={{ marginBottom: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>商家充积分设置</div>
                    <Form.Item
                        label="每天充负积分额度"
                    >
                        {getFieldDecorator('system_reduce_points_value', {
                            rules: [{ required: false }],
                            initialValue: data.system_reduce_points_value
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item
                        label="每天充正积分额度"
                    >
                        {getFieldDecorator('system_add_points_value', {
                            rules: [{ required: false }],
                            initialValue: data.system_add_points_value
                        })(<Input />)}
                    </Form.Item>


                    <Form.Item
                        label="积分兑换比例($1所需积分)"
                    >
                        {getFieldDecorator('system_points_exchange_set', {
                            rules: [{ required: false }],
                            initialValue: data.system_points_exchange_set
                        })(<Input />)}
                    </Form.Item>




                </Card>





                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form >

        );
    }
}

export default withRouter(Form.create()(Behavior))
