import React from 'react'
import { withRouter } from 'react-router-dom'
import { Descriptions, Card, Divider, Table, Button } from 'antd';
import { getUserDetail, GetOrderListE } from "../../action/authAction";
import { putOrder, getUserCouponList } from "../../action/goodAction"
let currency_symbol = "$"


class PlugInList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            data: {}, 
            tableData: [] , 
            couponData: "",
             
            orderData: "", 
            visible: false,
            message: "",
            ItemId: "",
            is_authorization_code: false,
            is_coupon: false,
            tabType: "order",
            page: 1





        };
    }

    async componentDidMount() {
        const id = this.props.location.search.substring(1);
        if (id) {
            let res = await getUserDetail(id);
            this.setState({ data: res.data })



            // let shopData = await getPartner();
            
            //this.HandleGetCouponData(this.state.page)
            

            this.HandleGetOrderData(this.state.page)


            /*
            this.setState({
                data: res.data,
                is_authorization_code: shopData.data.is_authorization_code === 1,
                is_coupon: shopData.data.is_coupon === 1,
                tabType: shopData.data.is_authorization_code === 1 ? "order" : "coupon"
            })
            */







            //订单信息
            /*
            GetOrderListE(id).then(res => {
                this.setState({ tableData: res.data.data })
            })
            */


        }

    }




      HandleGetCouponData = (page) => {
         const id = this.props.location.search.substring(1);
        getUserCouponList(page, "?user_id=" + id).then(res => {
            this.setState({
                couponData: res.data.data,
                couponMeta: res.data.meta.pagination
            })
        })
        }




        HandleGetOrderData = (page) => {


            //订单信息
            
            /*
            GetOrderListE(id).then(res => {
                this.setState({ tableData: res.data.data })
            })
            */ 

           // const id = this.props.location.pathname.substring(12)
            const id = this.props.location.search.substring(1);


            

            GetOrderListE(page, id).then(res => {
                this.setState({
                    tableData: res.data.data,
                    orderMeta: res.data.meta.pagination
                })
            })
        }








    render() {
        const { data, tableData, couponData,is_authorization_code, is_coupon, tabType,  couponMeta, orderMeta } = this.state;


        const couponColumns = [
            {
                title: "ID",
                dataIndex: 'id',
                width: '2%',
                align: "center",
            },


             {
                title: '店铺名称',
                dataIndex: ["coupon","partner", "title"],
                width: '7%',
                align: "center"
            },



            {
                title: "名称",
                dataIndex: 'coupon',
                width: '5%',
                align: "center",
                render: (record) => {
                    return <span>{record.name}</span>
                }
            },
            {
                title: "优惠券编号",
                dataIndex: 'unique_code',
                width: '5%',
                align: "center",
            },

            {
                title: "折扣",
                dataIndex: "coupon",
                width: '4%',
                align: "center",
                render: (record) => {
                    let discount_on_fixed
                    if (record.discount_type === "fixed") {
                        discount_on_fixed = currency_symbol + record.discount_on_fixed
                    } else {
                        discount_on_fixed = (parseFloat(record.discount_on_percentage) * 100).toFixed(0) + "%"
                    }
                    return discount_on_fixed
                }
            },
            {
                title: "领取时间",
                width: '8%',
                align: "center",
                render: (record) => {
                    return record.created_at && record.created_at.date && record.created_at.date.substring(0, 19)
                }
            },
            {
                title: "使用时间",
                dataIndex: "use_at",
                width: '8%',
                align: "center",
                render: (record) => {
                    return record && record.date.substring(0, 19)
                }
            },
            {
                title: "状态",
                width: '3%',
                align: "center",
                render: (record) => {
                    return (
                        <div>
                            {
                                record.end_status ?
                                    <span style={{ color: "#ff0000" }}>已结束</span> :
                                    <span style={{ color: "green" }}>正常</span>
                            }
                        </div>
                    )
                }
            }
        ];




        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                width: '5%',
                align: "center",
            },
            {
                title: '店铺名称',
                dataIndex: ["partner", "title"],
                width: '7%',
                align: "center"
            },


            {
                title: '订单编号',
                dataIndex: 'order_sn',
                width: '7%',
                align: "center",
            },


            /*
            {
                title: '订单原价',
                dataIndex: 'origin_price',
                width: '7%',
                align: "center",
            },
           
            {
                title: '钱包抵现',
                dataIndex: 'use_user_money',
                width: '8%',
                align: "center",
            },
            {
                title: '配送费',
                dataIndex: 'delivery_price',
                width: '6%',
                align: "center",
            },
            {
                title: '包装费',
                dataIndex: 'partner_pack_charge',
                width: '6%',
                align: "center",
            },
            {
                title: '服务费',
                dataIndex: 'service_charge',
                width: '6%',
                align: "center",
            },
            */

            {
                title: '商品',
                width: '15%',
                render: (text, record) => {
                    const data = record.orderSpu && record.orderSpu.data
                    if (record.spu_discount_id > 0) {
                        let attach_data = data[0].attach_data.spu_discount
                        return <div style={{ display: "flex" }}>
                            <img src={attach_data.image} style={{ width: "50px", height: "50px", objectFit: "contain", marginRight: "10px" }} alt="" />
                            <div>
                                {
                                    record.spu_many_help_id > 0 ?
                                        <span>
                                            <span style={{ color: "#ff0000" }}>[砍一刀]</span>{attach_data.name}
                                        </span> :
                                        <span>
                                            <span style={{ color: "#ff0000" }}>[促销]</span>{attach_data.name}
                                        </span>
                                }
                            </div>
                        </div>
                    } else {
                        return (
                            <div>
                                {
                                    data && data.map(item => {
                                        return (<div key={item.id.toString() + item.order_id} style={{ display: "flex", marginBottom: "5px" }}>
                                            <img src={item.attach_data.image} style={{ width: "50px", height: "50px", objectFit: "contain", marginRight: "10px" }} alt="" />
                                            <div className="good-name" style={{ width: "70px" }}>
                                                <div style={{ textAlign: "left" }} title={item.attach_data.name}>{item.attach_data.name}</div>
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    {
                                                        item.attach_data && item.attach_data.spu_sku && item.attach_data.spu_sku.spu_sku_goods_specification_item && item.attach_data.spu_sku.spu_sku_goods_specification_item.map((key, index) => {
                                                            return (
                                                                <span key={item.order_id + key.name + key.goods_specification_id + index + Math.random()} style={{ color: "#878FA5", fontSize: 12, textAlign: "left", margin: "5px 0px" }}>{key.name}</span>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <div>
                                                    {
                                                        item.attach_data && item.attach_data.spu_goods_annex_type && item.attach_data.spu_goods_annex_type.map(key => {
                                                            let spu_goods_annex_type_item = key.spu_goods_annex_type_item
                                                            return (
                                                                <div key={item.order_id + Math.random()}>
                                                                    {spu_goods_annex_type_item && spu_goods_annex_type_item.map((type_key, i) => {
                                                                        let goods_annex_type_item = type_key.goods_annex_type_item
                                                                        return <p key={i + goods_annex_type_item.name + Math.random()} style={{ color: "#878FA5", fontSize: 12, textAlign: "left", margin: "5px 0px" }}>{goods_annex_type_item.name} x {goods_annex_type_item.quantity}</p>
                                                                    })}
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>)
                                    })
                                }
                            </div>
                        )
                    }

                }
            },

            {
                title: '付款金额',
                dataIndex: 'price',
                width: '7%',
                align: "center",
                render: (record) => {
                    return <span>${record}</span>
                }
            },

            /*
            {
                title: '支付状态',
                width: '7%',
                align: "center",
                render: (text, record) => {
                    return (
                        <span style={{ color: record.is_pay === 1 ? "green" : "#ff0000" }}>{record.is_pay === 0 ? "未付款" : "已付款"}</span>
                    )
                }
            },
            {
                title: '取货方式',
                width: '7%',
                align: "center",
                render: (text, record) => {
                    return (
                        <span >{record.service_type === 1 ? "到店" : "配送"}</span>
                    )
                }
            },
           
            {
                title: '使用状态',
                width: '6%',
                align: "center",
                render: (text, record) => {
                    return (
                        <span style={{ color: record.is_finish === 1 ? "green" : "#ff0000" }}>{record.is_finish === 1 ? "已使用" : "未使用"}</span>
                    )
                }
            },
            {
                title: '提现状态',
                width: '7%',
                align: "center",
                render: (text, record) => {
                    return (
                        <span style={{ color: record.is_extract === 1 ? "green" : "#ff0000" }}>{record.is_extract === 1 ? "已提现" : "未提现"}</span>
                    )
                }
            },
            */


            {
                title: '订单状态',
                dataIndex: "order_status",
                width: '6%',
                align: "center",
                render: (record) => {
                    switch (record) {
                        case "un_pay":
                            return "未付款";
                        // break;
                        case "wait_order_receiving":
                            return "待接单";
                        // break;
                        case "un_order_receiving":
                            return "退款中";
                        // break;
                        case "for_consumption":
                            return "待消费";
                        // break;
                        case "finished":
                            return "已完成";
                        // break;
                        case "canceled":
                            return "已过期";
                        // break;
                        case "rejected":
                            return "已拒绝";
                        // break;
                        case "refunded":
                            return "已退款";
                        // break;
                        default: return ""


                    }
                }
            },

            {
                title: '操作',
                width: '5%',
                align: "center",
                render: (text, record) => {
                    return (
                        <Button onClick={() => {
                            this.props.history.push("/orderdetail/" + record.id)
                        }}>查看</Button>
                    )
                }
            },

        ];




        return (
            <Card>
                <Descriptions title="基本信息">
                    <Descriptions.Item label="头像">{data?.head_portrait && <img style={{ width: "50px", height: "50px", objectFit: "cover" }} src={data?.head_portrait} alt="" />}</Descriptions.Item>
                    <Descriptions.Item label="UUID">{data?.uuid}</Descriptions.Item>
                    {/* <Descriptions.Item label="用户名">{data?.name}</Descriptions.Item> */}
                    <Descriptions.Item label="用户昵称">{data?.name}</Descriptions.Item>
                   
                    <Descriptions.Item label="area code">{data?.area}</Descriptions.Item>
                    <Descriptions.Item label="手机号">{data?.phone}</Descriptions.Item>

                    <Descriptions.Item label="邮箱">{data?.email}</Descriptions.Item> 

                    {/* <Descriptions.Item label="余额">{data?.money}</Descriptions.Item>*/}
                    {/* <Descriptions.Item label="积分">{data?.integral}</Descriptions.Item>*/}
                    {/* <Descriptions.Item label="国家">{data?.area}</Descriptions.Item> */}
                    {/* <Descriptions.Item label="默认语言">{data?.language_id}</Descriptions.Item> */}

                </Descriptions>
                <Divider />



                 {
                       
                        <div style={{ marginBottom: "20px" }}>
                           
                            <div>


                                {
                                     
                                    <Button
                                        style={{ marginRight: "5px", backgroundColor: tabType === "coupon" ? "#1DA57A" : "", color: tabType === "coupon" ? "#fff" : "rgba(0, 0, 0, 0.65)"  }}
                                        onClick={() => {
                                            this.setState({
                                                tabType: "coupon"
                                            }, () => {
                                                this.HandleGetCouponData(1)
                                            })
                                        }}>
                                       优惠券
                                    </Button>
                                }

                                
                                {
                                    
                                    <Button
                                        style={{marginRight: "5px", backgroundColor: tabType === "order" ? "#1DA57A" : "", color: tabType === "order" ? "#fff" : "rgba(0, 0, 0, 0.65)"  }}
                                        onClick={() => {

                                            this.setState({
                                                tabType: "order"
                                            }, () => {
                                                this.HandleGetOrderData(1)
                                            })
                                        }}>
                                        订单
                                    </Button>
                                }

                                

                            </div>
                        </div>
                    }





                    {
                        tabType === "coupon"   &&
                        <Table
                            rowKey={record => record.id}
                            dataSource={couponData}
                            columns={couponColumns}
                            pagination={{
                                pageSize: couponMeta && couponMeta.per_page,
                                total: couponMeta && couponMeta.total
                            }}
                            onChange={(e) => {
                                this.HandleGetCouponData(e.current)
                            }}
                        />
                    }





                {/*<Descriptions title="订单信息"></Descriptions>*/}



                     {
                        tabType === "order" &&
                            <Table
                                rowKey='id'
                                dataSource={tableData}
                                columns={columns}
                                pagination={{
                                    hideOnSinglePage: false
                                }}
                            />

                        }



            </Card>

        );
    }
}

export default withRouter(PlugInList)

