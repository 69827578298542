import React, { Component } from 'react'
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom'
import { getTokenStorage } from "./util/function";


import Login from "../src/page/login/index"
import Admin from "./admin";
import SysTemSet from "./page/set/set"
import WithdrawLog from "./page/withdrawLog/withdrawLog"
import withdrawLogDetail from "./page/withdrawLog/withdrawLogDetail"
import UserWithdrawLog from "./page/userWithdrawLog/userwithdrawLog"
import UserWithdrawLogDetail from "./page/userWithdrawLog/userwithdrawLogDetail"


import userOrderRefund from "./page/userOrderRefund/userOrderRefund"
import userOrderRefundDetail from "./page/userOrderRefund/userOrderRefundDetail"




import UserRefund from "./page/userRefund/userRefund"
import SystemRecharge from "./page/user/systemRecharge"
import UserRefundDetail from "./page/userRefund/userRefundDetail"
import ShopList from "./page/shopList/shoplist"
import Shopbehavior from "./page/shopList/shopbehavior"
import PaypalSet from "./page/shopList/RoyalConfig" // royalpay config 已取消
import StripeSet from "./page/shopList/StripeConfig"
import EditShop from "./page/shopList/editShop"
import NewShop from "./page/shopList/newShop"
import RecommendList from "./page/shopList/recommend"
import PhoneLogList from "./page/shopList/phonelog"
import NewAnnouncement from "./page/Announcement/newAnnouncement";
import AnnouncementList from "./page/Announcement/AnnouncementList";
import EditAnnouncement from "./page/Announcement/EditAnnouncement";
import Agreement from "./page/Agreement/Agreement";
import Privacy from "./page/Agreement/Privacy";
import UserList from "./page/user/userlist";
import UserDetail from "./page/user/userDetail";
import contactUs from "./page/user/contact_us";
import NewPackage from "./page/package/Newpackage";
import PackageList from "./page/package/packageList";
import PackageDetail from "./page/package/packageDetail";
import ApplyPackageList from "./page/package/applyPackageList";
import PackageLogList from "./page/shopList/packageLog/packageLogList";
import NewPackageLog from './page/shopList/packageLog/NewPackageLog';  //新建套餐日志
import PlugInList from "./page/plugIn/plugInList";
import PlugInEdit from "./page/plugIn/plugInEdit";
import PlugInSet from "./page/plugIn/plugInSet";

import AdminList from "./page/admin/adminList"
import orderList from "./page/order/orderList";
import orderDetail from "./page/order/orderDetail";
import LangList from './page/language/langList';
import LinkBussness from './page/language/linkBussness';
import MyOrders from './page/myOrders/index';
import MyOrdersDetail from './page/myOrders/detail';
import MySubscription from './page/subscription/index';
import MySubscriptionDetail from './page/subscription/detail';
import StyleList from './page/style/styleList';
import NewStyle from './page/style/NewStyle';
import EditStyle from './page/style/EditStyle';
import Advertisement from './page/advertisement/AdvertisementList';
import NewAdvertisement from './page/advertisement/NewAdvertisement';
import EditAdvertisement from './page/advertisement/EditAdvertisement';

import SalesAreaUnionList from './page/salesAreaUnion/SalesAreaUnionList'; //联合大区 
import NewSalesAreaUnion from './page/salesAreaUnion/NewSalesAreaUnion';//联合大区 
import EditSalesAreaUnion from './page/salesAreaUnion/EditSalesAreaUnion';//联合大区 

import SalesAreaList from './page/salesArea/salesAreaList'; //销售大区
import SalesStateList from './page/salesState/salesStateList';
import NewSalesArea from './page/salesArea/NewSalesArea';
import EditSalesArea from './page/salesArea/EditSalesArea';
import SalesAreaSubList from './page/salesAreaSub/SalesAreaSubList';
import NewSalesAreaSub from './page/salesAreaSub/NewSalesAreaSub';
import EditSalesAreaSub from './page/salesAreaSub/EditSalesAreaSub';
import RegionList from './page/region/regionList';
import NewRegion from './page/region/NewRegion';
import EditRegion from './page/region/EditRegion';
import AddressSub from './page/salesArea/subList';
import DataStatistics from './page/statistics/index';
import navList from './page/navList/list';
import Behavior from './page/behavior/index';
import payConfig from './page/set/payConfig';
import StripeConfig from './page/set/StripeConfig';
import PaypalConfig from './page/set/PaypalConfig';
import RoyalConfig from './page/set/RoyalConfig';
import SiteCountry from './page/set/SiteCountry';
import AnnualFeeList from './page/shopList/annualFee/AnnualFeeList';  //年费流水
import orderFeeList from './page/shopList/annualFee/orderFeeList';  //账单
import orderChargeFeeList from './page/shopList/annualFee/orderChargeFeeList';  //服务费
import statementList from './page/shopList/annualFee/statementList';  //结算清单
import statementDetail from './page/shopList/annualFee/statementDetail';  //结算清单




import userWalletList from './page/user/userWalletList';  //用户交易记录

import PrinterList from './page/set/printer/PrinterList';
import PrinterAllList from './page/set/printer/PrinterAllList';
import printerCategoryList from './page/set/printer/printerCategoryList'; // 
import CouponList from "./page/coupon/CouponList"
import GoodsList from "./page/goods/GoodsList"
import UserCoupon from "./page/coupon/userCoupon"
import promotionalgoodsList from "./page/promotionalgoods/promotionalgoodsList"
import Editpromotionalgoods from "./page/promotionalgoods/Editpromotionalgoods"
import Newpromotionalgoods from "./page/promotionalgoods/Newpromotionalgoods"
import NewHoliday from "./page/holiday/NewHoliday"
import EditHoliday from "./page/holiday/EditHoliday"
import holidayList from "./page/holiday/holidayList"
import salesAreaSubList from './page/salesAreaSub/SalesAreaSubList'

import documentList from "./page/document/documentList"
import NewDocument from "./page/document/NewDocument"
import EditDocument from "./page/document/EditDocument"


import CategoryList from "./page/documentcategory/CategoryList"
import NewCategory from "./page/documentcategory/NewCategory"
import EditCategory from "./page/documentcategory/EditCategory"


import LeetCode from './page/leetcode';

//新增empartner
import EmpartnerList from "./page/empartnerList/empartnerList" 
import editEmpartner from "./page/empartnerList/editEmpartner"
import NewEmpartner from "./page/empartnerList/newEmpartner"


class Router extends Component {
    render() {
        return (
            <HashRouter>
                <Switch>
                    <Route path='/' exact>
                        {
                            getTokenStorage() ?
                                <Redirect to='/orderlist' /> :
                                <Redirect to='/login' />
                        }

                    </Route>
                    <Route path='/login' component={Login} />
                    <Route
                        render={() => {
                            return (
                                <Admin>
                                    <Switch>
                                        <Route path='/set' component={SysTemSet} />
                                        <Route path='/withdrawLog' component={WithdrawLog} />
                                        <Route path='/withdrawLogDetail' component={withdrawLogDetail} />
                                        
                                        <Route path='/userwithdrawLog' component={UserWithdrawLog} />
                                        <Route path='/userwithdrawLogDetail' component={UserWithdrawLogDetail} />

                                        <Route path='/userOrderRefund' component={userOrderRefund} />
                                        <Route path='/userOrderRefundDetail' component={userOrderRefundDetail} />

 
                                        <Route path='/SystemRecharge' component={SystemRecharge} />
                                        <Route path='/userRefund' component={UserRefund} />
                                        <Route path='/userRefundDetail' component={UserRefundDetail} />
                                        <Route path='/shop' component={ShopList} />
                                        <Route path='/Shopbehavior' component={Shopbehavior} />
                                        <Route path='/PaypalSet' component={PaypalSet} />
                                        <Route path='/StripeSet' component={StripeSet} />
                                        <Route path='/editshop' component={EditShop} />
                                        <Route path='/newShop' component={NewShop} />
                                        <Route path='/recommend' component={RecommendList} />
                                        <Route path='/phonelog' component={PhoneLogList} />
                                        <Route path='/NewAnnouncement' component={NewAnnouncement} />
                                        <Route path='/AnnouncementList' component={AnnouncementList} />
                                        <Route path='/EditAnnouncement' component={EditAnnouncement} />
                                        <Route path='/Agreement' component={Agreement} />
                                        <Route path='/Privacy' component={Privacy} />
                                        <Route path='/user' exact component={UserList} />
                                        <Route path='/userDetail' component={UserDetail} />
                                        <Route path='/contactUs' component={contactUs} />
                                        <Route path='/admin' component={AdminList} />
                                        <Route path='/NewPackage' component={NewPackage} />
                                        <Route path='/ApplyPackage' component={ApplyPackageList} />
                                        <Route path='/PackageList' component={PackageList} />
                                        <Route path='/PackageDetail' component={PackageDetail} />
                                        <Route path='/PackageLogList' component={PackageLogList} />
                                        <Route path='/PlugInList' component={PlugInList} />
                                        <Route path='/PlugInEdit' component={PlugInEdit} />
                                        <Route path='/PlugInSet' component={PlugInSet} />

                                        
                                        <Route path='/orderlist' component={orderList} />
                                        <Route path='/orderdetail' component={orderDetail} />
                                        <Route path='/language' component={LangList} />
                                        <Route path='/businessLinkLang' component={LinkBussness} />
                                        <Route path='/myOrders' component={MyOrders} />
                                        <Route path='/myOrderDetail' component={MyOrdersDetail} />
                                        <Route path='/mySubscription' component={MySubscription} />
                                        <Route path='/mySubscriptionDetail' component={MySubscriptionDetail} />
                                        <Route path='/styleList' component={StyleList} />
                                        <Route path='/newStyle' component={NewStyle} />
                                        <Route path='/editStyle' component={EditStyle} />
                                        <Route path='/Advertisement' component={Advertisement} />
                                        <Route path='/NewAdvertisement' component={NewAdvertisement} />
                                        <Route path='/EditAdvertisement' component={EditAdvertisement} />
                                        <Route path='/salesAreaList' component={SalesAreaList} />
                                        <Route path='/SalesAreaUnionList' component={SalesAreaUnionList} />
                                        <Route path='/salesStateList' component={SalesStateList} />
                                        <Route path='/newSalesArea' component={NewSalesArea} />
                                        <Route path='/editSalesArea' component={EditSalesArea} /> 
                                        <Route path='/NewSalesAreaUnion' component={NewSalesAreaUnion} />
                                        <Route path='/EditSalesAreaUnion' component={EditSalesAreaUnion} /> 
                                        <Route path='/salesAreaSubList' component={salesAreaSubList} />
                                        <Route path='/NewSalesAreaSub' component={NewSalesAreaSub} />
                                        <Route path='/EditSalesAreaSub' component={EditSalesAreaSub} />
                                        <Route path='/regionList' component={RegionList} />
                                        <Route path='/newRegion' component={NewRegion} />
                                        <Route path='/editRegion' component={EditRegion} />
                                        <Route path='/addressSub' component={AddressSub} />
                                        <Route path='/dataStatistics' component={DataStatistics} />
                                        <Route path='/navList' component={navList} />
                                        <Route path='/behavior' component={Behavior} />

                                        <Route path='/payConfig' component={payConfig} />
                                        <Route path='/StripeConfig' component={StripeConfig} />
                                        <Route path='/PaypalConfig' component={PaypalConfig} />
                                        <Route path='/RoyalConfig' component={RoyalConfig} />
                                        <Route path='/SiteCountry' component={SiteCountry} />
                                        <Route path='/AnnualFeeList' component={AnnualFeeList} />
                                        <Route path='/orderFeeList' component={orderFeeList} />
                                        <Route path='/orderChargeFeeList' component={orderChargeFeeList} />
                                        <Route path='/statementList' component={statementList} />
                                        <Route path='/statementDetail' component={statementDetail} />
                                        <Route path='/userWalletList' component={userWalletList} />
                                        <Route path='/NewPackageLog' component={NewPackageLog} />
                                        <Route path='/PrinterList' component={PrinterList} />
                                        <Route path='/PrinterAllList' component={PrinterAllList} />
                                        <Route path='/printerCategoryList' component={printerCategoryList} />
                                        <Route path='/GoodsList' component={GoodsList} />
                                        <Route path='/couponList' component={CouponList} />
                                        <Route path='/userCoupon' component={UserCoupon} />
                                        <Route path='/promotionalgoodsList' component={promotionalgoodsList} />
                                        <Route path='/editpromotionalgoods' component={Editpromotionalgoods} />
                                        <Route path='/newpromotionalgoods' component={Newpromotionalgoods} />
                                        <Route path='/NewHoliday' component={NewHoliday} />
                                        <Route path='/EditHoliday' component={EditHoliday} />
                                        <Route path='/holidayList' component={holidayList} />
                                        <Route path='/documentList' component={documentList} />
                                        <Route path='/NewDocument' component={NewDocument} />
                                        <Route path='/EditDocument' component={EditDocument} />


                                        <Route path='/leetCode' component={LeetCode} /> 
                                        <Route path='/empartner' component={EmpartnerList} /> 
                                        <Route path='/editEmpartner' component={editEmpartner} />
                                        <Route path='/newEmpartner' component={NewEmpartner} />


                                        <Route path='/CategoryList' component={CategoryList} />
                                        <Route path='/NewCategory' component={NewCategory} />
                                        <Route path='/EditCategory' component={EditCategory} />





                                    </Switch>
                                </Admin>
                            )
                        }}
                    />
                </Switch>
            </HashRouter>
        )
    }
}

export default Router
