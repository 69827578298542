import { BaseUrl } from './function';
export const API = {
    BASEURL: BaseUrl() + "/api/system/",
    EmailBASEURL: BaseUrl() + "/api/",
    auth: {
        LOGIN: "login",
        partnerAddress: "system",
    },

    category: {
        NEWCATEGORY: "category/",
        EDITCATEGORY: "category/update/"
    },

    NewGood: {
        spu: "spu",
        include: "?include=spuSku,spuGoodsSpecification,spuGoodsAttr,spuRecommendCategory,spuGoodsAnnexType.spuGoodsAnnexTypeItem",
        include2: "&include=partner,spuSku,spuGoodsSpecification,spuGoodsAttr,category",
        UPDATE: "/update",
        recommend: "&recommend=1"
    },

    page: "?page=",
    search: "&search=",

    user: {
        user: "user",
        include: "?include=partner",
        page: "&page=",
        phone: "&phone=",
        contactUs: "contactUs"
    },
    set: "set",
    setConfigs: "setConfigs",
    setConfig: "setConfig",
    getConfig: "getConfig",
    sendUpdatePayParamCodeMail: "sendUpdatePayParamCodeMail",
    config: "config?partner_id=0",
    package: "package",
    partnerApplyPackage: "partnerApplyPackage",
    packageLog: {
        packageLog: "packageLog",
        include: "?include=package,partner"
    },
    plugin: "addon",
    withdrawLog: {
        withdrawLog: "withdrawLog",
        userWithdrawLog: "userWithdrawLog",
        dispose: "/dispose"
    },


    UserOrderRefundLog: {
         
        UserOrderRefundLog: "userRefundLog",
        dispose: "/dispose"
    },



    shopList: {
        partner: "partner",
        include: "?include=partnerAddress,partnerFoodLicense",
        page: "?page=",
        page2: "&page="
    },


    empartnerList: {
        partner: "empartner",
        include: "?include=salesArea,empartnerAddress",
        page: "?page=",
        page2: "&page="
    },





    systemAnnouncement: "systemAnnouncement",
    importPartner: {
        importPartner: "importPartner",
        has_call: "?has_call_phone=1"
    },
    systemUser: "systemUser",
    order: {
        order: "order",
        include: "?include=user,orderSpu,partner",
        partner_id: "&partner_id=",
 
        orderLine: "orderLine", 
        includeUser: "?include=orderSpu,user",
        phone: "&phone=",
        orderSn: "&order_sn=",
        orderReceiving: "orderReceiving/",
        confirmOrder: "confirmOrder",
        exportOrder: "exportOrder",
        orderExportSecret: "orderExportSecret" 
    },

    
    godPassword: "godPassword",
    language: "language",
    partnerLang: "partnerLangue",
    region: "region",
    style: "style",
    state: "state",
    salesAreaUnion: "salesAreaUnion",
    salesArea: "salesArea",
    salesAreaSub: "salesAreaSub",
    address: "address",
    partnerPackageFeeLog: "partnerPackageFeeLog",
    partnerPackageStatementLog:"partnerPackageStatementLog",//结算清单
    createStatement:"createStatement",//生成结算清单

    partnerNav: "partnerNav",
    printer: "printer",
    printerCategory: "printerCategory",
    queryPrinterStatus: "queryPrinterStatus?sn=",
    dataSum: "dataSum",
    spuDiscount: "spuDiscount",
    coupon: "coupon",
    userCoupon: "userCoupon",
    is_discount: "?is_discount=1&include=spu.partner",
    spu: "spu",
    orderRefund: "orderRefund/",
    orderRefundGoods: "orderRefundGoods/",
    
    userMoneyLog: "userMoneyLog?source=system_add",
    holiday: "holiday",
    holidayImport: "holidayImport",
    waitRefund: "waitRefund/",
    verifyCode: "verifyCode",
    systemAdvertisement: "systemAdvertisement",
    document: "document",
    documentcategory: "documentcategory"
}


